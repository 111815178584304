<template>
  <v-dialog v-model="dialogBaixaEmLotesAf" persistent width="1100px">
    <v-card>
      <v-card-title class="px-6 tocs_gray_1">
        {{ $tc("global.lancamentoEmLote") }}
        <v-spacer></v-spacer>
        <v-btn @click="$emit('update:dialogBaixaEmLotesAf', false)" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-4">
        <div class="d-flex">
          <v-btn
            v-for="item in menus"
            :key="item.title"
            class="mr-4"
            @click="openDialog(item)"
            color="tocs_gray_1"
          >
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-btn>
          <v-checkbox
            v-model="mesmo_cliente"
            :disabled="multiplosClientesComputed"
            label="Mesmo cliente"
            class="mt-1"
          >
          </v-checkbox>
        </div>
        <div class="mt-6" v-if="!lancamentos.length">
          Nenhum lançamento adicionado.
        </div>
        <div v-if="lancamentos.length">
          <div class="mt-6 mb-2">
            Lançamentos realizados ({{
              lancamentos.length ? lancamentos[0].moeda_sigla : ""
            }})
          </div>
          <ListaLancamentos
            :total.sync="total"
            :lancamentos="lancamentos"
          ></ListaLancamentos>
        </div>
        <DialogVendasEmLoteAf
          v-if="dialogVendasEmLoteAf"
          :cliente="clienteCondicionalComputed"
          :show.sync="dialogVendasEmLoteAf"
          @add="addLancamentos"
        />
        <DialogDevolucoesEmLoteAf
          v-if="dialogDevolucoesEmLoteAf"
          :cliente="clienteCondicionalComputed"
          :show.sync="dialogDevolucoesEmLoteAf"
          @add="addLancamentos"
        />
        <DialogContasReceberEmLoteAf
          v-if="dialogContasReceberEmLoteAf"
          :cliente="clienteCondicionalComputed"
          :show.sync="dialogContasReceberEmLoteAf"
          @add="addLancamentos"
        />
        <DialogContasPagarEmLoteAf
          v-if="dialogContasPagarEmLoteAf"
          :cliente="clienteCondicionalComputed"
          :show.sync="dialogContasPagarEmLoteAf"
          @add="addLancamentos"
        />
        <DialogGastosEmLoteAf
          v-if="dialogGastosEmLoteAf"
          :cliente="clienteCondicionalComputed"
          :show.sync="dialogGastosEmLoteAf"
          @add="addLancamentos"
          :tipo_caixa="tipo_caixa"
        />
        <DialogFaturar
          v-if="dialogFaturar"
          :show.sync="dialogFaturar"
          :valor="total"
          :moeda="lancamentos[0].moeda_sigla"
          :tipo_caixa="tipo_caixa"
          @timbrado="setTimbrado"
          @faturar="faturar"
        />
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn
          color="button_1 white--text"
          :disabled="!lancamentos.length"
          @click="dialogFaturar = true"
        >
          <v-icon class="mr-2">mdi-currency-usd</v-icon>
          {{ $tc("global.pagamento") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getClienteByFornecedorId } from "@/api/fornecedores/fornecedores.js";
import { efetuaBaixaEmLoteAf } from "@/api/baixa-em-lote/baixa-em-lote.js";

export default {
  name: "DialogBaixaEmLotesAf",

  props: {
    dialogBaixaEmLotesAf: {
      type: Boolean,
      default: false,
    },
    caixa_id: {
      default: null,
      type: [String, Number],
    },
    caixa_af_id: {
      default: null,
    },
    tipo_caixa: {
      default: "PDV",
    },
  },

  components: {
    DialogVendasEmLoteAf: () => import("./vendas/DialogVendasEmLoteAf.vue"),
    DialogDevolucoesEmLoteAf: () =>
      import("./devolucao/DialogDevolucoesEmLoteAf.vue"),
    DialogContasReceberEmLoteAf: () =>
      import("./contas-receber/DialogContasReceberEmLoteAf.vue"),
    DialogContasPagarEmLoteAf: () =>
      import("./contas-pagar/DialogContasPagarEmLoteAf.vue"),
    DialogGastosEmLoteAf: () => import("./gastos/DialogGastosEmLoteAf.vue"),
    ListaLancamentos: () => import("./ListaLancamentos.vue"),
    DialogFaturar: () => import("./DialogFaturar.vue"),
  },

  data() {
    return {
      cliente: null,
      mesmo_cliente: true,
      dialogVendasEmLoteAf: false,
      dialogDevolucoesEmLoteAf: false,
      dialogContasReceberEmLoteAf: false,
      dialogContasPagarEmLoteAf: false,
      dialogGastosEmLoteAf: false,
      dialogFaturar: false,
      dados_baixa_em_lote_finalizada: {},
      lancamentos: [],
      total: 0,
      selectedTimbrado: null,
      menus: [
        {
          title: "Venda",
          icon: "mdi-tag-outline",
          action: "openDialogVendas",
        },
        {
          title: "Devolução",
          icon: "mdi-autorenew",
          action: "openDialogDevolucoes",
        },
        {
          title: "Contas Receber",
          icon: "mdi-piggy-bank-outline",
          action: "openDialogContasReceber",
        },
        {
          title: "Contas Pagar",
          icon: "mdi-cash-multiple",
          action: "openDialogContasPagar",
        },
        {
          title: "Gastos",
          icon: "mdi-currency-usd",
          action: "openDialogGastos",
        },
      ],
    };
  },

  computed: {
    multiplosClientesComputed() {
      let clientes = {};

      const addUniqueCliente = (cliente) => {
        if (cliente && !clientes[cliente.id]) {
          clientes[cliente.id] = cliente;
        }
      };

      for (const item of this.lancamentos) {
        addUniqueCliente(item.cliente);
      }
      return Object.keys(clientes).length > 1;
    },

    clienteCondicionalComputed() {
      if (this.mesmo_cliente) {
        return this.cliente;
      } else {
        return null;
      }
    },
  },

  methods: {
    setTimbrado(timbrado) {
      this.selectedTimbrado = timbrado;
    },
    async faturar(forma_pagamento) {
      let payload = {
        lancamentos: this.lancamentos,
        total: this.total,
        forma_pagamento: forma_pagamento,
        caixa_id: Number(this.caixa_id),
        caixa_af_id: this.caixa_af_id,
        tipo_caixa: this.tipo_caixa,
      };

      if (this.selectedTimbrado) {
        payload.timbrado_id = this.selectedTimbrado;
      }
      this.$toast.success("Em construcao!");
      
      // await efetuaBaixaEmLoteAf(payload).then((response) => {
      //   if (response.status === 200) {
      //     this.$toast.success("Lançamentos realizados com sucesso!");
      //     this.dialogFaturar = false;
      //     this.$emit(
      //       "mostrar-baixa-em-lote-finalizada",
      //       response.data.lancamentos
      //     );
      //     this.$emit("fetch-movimentacoes");
      //     this.$emit("update:dialogBaixaEmLotesAf", false);
      //   } else {
      //     this.$toast.error("Erro ao realizar lançamentos!");
      //   }
      // });
    },
    openDialog(item) {
      if (item.action === "openDialogVendas") {
        this.dialogVendasEmLoteAf = true;
      }
      if (item.action === "openDialogDevolucoes") {
        this.dialogDevolucoesEmLoteAf = true;
      }
      if (item.action === "openDialogContasReceber") {
        this.dialogContasReceberEmLoteAf = true;
      }
      if (item.action === "openDialogContasPagar") {
        this.dialogContasPagarEmLoteAf = true;
      }
      if (item.action === "openDialogGastos") {
        this.dialogGastosEmLoteAf = true;
      }
    },
    addLancamentos(lancamento) {
      if (Array.isArray(lancamento)) {
        for (const item of lancamento) {
          this.addLancamentoPorMoeda(item);
        }
      } else {
        this.addLancamentoPorMoeda(lancamento);
      }
    },
    async addLancamentoPorMoeda(lancamento) {
      if (this.mesmo_cliente && !this.cliente) {
        if (lancamento.cliente) {
          this.cliente = lancamento.cliente;
        }
        if (lancamento.fornecedor) {
          let response = await getClienteByFornecedorId(
            lancamento.fornecedor.id
          );
          this.cliente = response;
        }
      }
      this.lancamentos.push(lancamento);
    },
  },
};
</script>
